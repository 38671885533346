import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EntitiesEnum, CustomEntitiesEnum } from '@match-fix/shared';
import { TcService } from '@tc/core';

import { Configuration } from './configuration';
import { BASE_PATH } from './variables';

@Injectable({
  providedIn: 'root'
})
export class CrudService<
  TResult extends { id: number } = any
> extends TcService {
  protected basePath = 'https://rec-matchfix.tanacorp.com/api';
  protected defaultHeaders = new HttpHeaders();
  protected configuration = new Configuration();

  constructor(
    private entityName: EntitiesEnum | CustomEntitiesEnum,
    public httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    super();

    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
  }

  async delete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<boolean> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .delete<any>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      })
      .toPromise<boolean>();
  }

  async create(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .post<TResult>(`${url}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      })
      .toPromise<TResult>();
  }

  async update(
    item: TResult,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .put<TResult>(`${url}/${item.id}`, item, {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      })
      .toPromise<TResult>();
  }

  async get(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<TResult> {
    const headers = this.defaultHeaders;
    const url = this.getEntityServiceEndpoint();
    return this.httpClient
      .get<TResult>(`${url}/${id}`, {
        withCredentials: true,
        headers,
        observe,
        reportProgress
      })
      .toPromise<TResult>();
  }

  public getEntityServiceEndpoint() {
    switch (this.entityName) {
      case EntitiesEnum.Referees:
        return `${this.configuration.basePath}/referees`;
      case EntitiesEnum.Matchs:
        return `${this.configuration.basePath}/matches`;
      case EntitiesEnum.Players:
        return `${this.configuration.basePath}/players`;
      case EntitiesEnum.MatchReferees:
        return `${this.configuration.basePath}/matchreferees`;
      case EntitiesEnum.Analysiss:
        return `${this.configuration.basePath}/analysiss`;
      case EntitiesEnum.Teams:
        return `${this.configuration.basePath}/teams`;
      case EntitiesEnum.TeamPlayers:
        return `${this.configuration.basePath}/teamplayers`;
      case EntitiesEnum.Clubs:
        return `${this.configuration.basePath}/clubs`;
      case EntitiesEnum.Chronos:
        return `${this.configuration.basePath}/chronos`;
      case EntitiesEnum.Events:
        return `${this.configuration.basePath}/events`;
      case EntitiesEnum.KeyPointAnalysiss:
        return `${this.configuration.basePath}/keypointanalysiss`;
      case EntitiesEnum.KeyPoints:
        return `${this.configuration.basePath}/keypoints`;
      case EntitiesEnum.Deficiencies:
        return `${this.configuration.basePath}/deficiencies`;
      case EntitiesEnum.Videos:
        return `${this.configuration.basePath}/videos`;
      case EntitiesEnum.Competitions:
        return `${this.configuration.basePath}/competitions`;
      case EntitiesEnum.Stadiums:
        return `${this.configuration.basePath}/stadiums`;
      case EntitiesEnum.Users:
        return `${this.configuration.basePath}/users`;
      case CustomEntitiesEnum.Auth:
        return `${this.configuration.basePath}/auth`;
      case EntitiesEnum.ClubPlayers:
        return `${this.configuration.basePath}/clubplayers`;
      case CustomEntitiesEnum.CustomPlayers:
        return `${this.configuration.basePath}/customplayers`;
      case EntitiesEnum.OrganisationUsers:
        return `${this.configuration.basePath}/organisation-user`;
      case EntitiesEnum.Organisations:
        return `${this.configuration.basePath}/organisations`;
      case EntitiesEnum.Countrys:
        return `${this.configuration.basePath}/countries`;
      case EntitiesEnum.Documents:
        return `${this.configuration.basePath}/documents`;
      case CustomEntitiesEnum.Reports:
        return `${this.configuration.basePath}/reports`;
      case EntitiesEnum.ListValues:
        return `${this.configuration.basePath}/listvalues`;
      default:
        return '';
    }
  }
}
